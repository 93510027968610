.QRmate {
    position: absolute;
    padding: 14px;
    background-color: #EB0E29;
}

.Code {
    background-color: black;
    position: relative;
    width: 100vw;
    height: 100vh;
}

.CodeCanvas {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: black;
    width: 100vw;
    height: 100vh;
}

.ShareTwitter {
    position: absolute;
    top: 0px;
    right: 0px;
    font-family: 'VCROSDMono';
    font-size: 20px;
    color: white;
    letter-spacing: 3.11px;
    line-height: 26px;
    cursor: pointer;
}

.ShareOwn {
    position: absolute;
    top: 40px;
    right: 0px;
    font-family: 'VCROSDMono';
    font-size: 20px;
    color: white;
    letter-spacing: 3.11px;
    line-height: 26px;
    cursor: pointer;
}

.ShareTwitter:hover {
    opacity: 0.6;
}

.ShareOwn:hover {
    opacity: 0.6;
}