.App {
    background-image: linear-gradient(180deg, #1A37E7 0%, #1327A7 100%);
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    padding: 15px;
    box-sizing: border-box;
  }
  
  .BottomBar {
    text-align: center;
    font-family: VCROSDMono;
    color: #6074BB;
  }
  
  .Motion {
    width: 250px;
    opacity: 0.75;
  }
  
  .Content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .Title {
    text-align: center;
    font-size: 72px;
    color: #1A37E7;
    letter-spacing: 7.61px;
    text-shadow: -1px -2px 3px rgba(255,255,255,0.34);
    font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif;
  }
  
  .Manual {
    display: flex;
    flex-direction: column;
  }
  
  .ConnectCoinbase {
    width: 250px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: VCROSDMono;
    color: #1A37E7;
    background: #6074BB;
    padding: 15px;
    border: 1px;
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .ConnectCoinbase:hover {
    opacity: 0.75;
  }
  
  .ConnectCoinbase:active {
    background: #999999;
  }
  
  .Custom {
    font-family: VCROSDMono;
    color: #6074BB;
    border: 1px solid #6074BB;
    background: rgba(0, 0, 0, 0);
    font-size: 17px;
    padding: 15px;
    width: 250px;
    box-sizing: border-box;
  }
  
  .Generate {
    width: 250px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: VCROSDMono;
    color: #6074BB;
    border: 1px solid #6074BB;
    background: rgba(0, 0, 0, 0);
    padding: 15px;
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 15px;
  }
  
  .Generate:hover {
    opacity: 0.75;
  }
  
  .Generate:active {
    background: #999999;
  }
  